<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <MainSection v-bind="mainProps" :isFullWidth="true" />
    <BookSection v-bind="bookProps" />
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import MainSection from '../../organisms/Carousel/Carousel.vue';
import BookSection from '../../organisms/BookingForm/BookingForm.vue';

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, MainSection, BookSection, SocialBox
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => { }
        },
        mainProps: {
            type: Object,
            default: () => { }
        },
        socialProps: {
            type: Object,
            default: () => { }
        },
        bookProps: {
            type: Object,
            default: () => { }
        },
    }
}
</script>
