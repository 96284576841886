<template>
  <HomeTemplate :headerProps="headerProps" :socialProps="socialProps" :mainProps="mainProps" :bookProps="bookProps" />
</template>

<script>
import HomeTemplate from "@/components/templates/Home/Home";
import LogoImg from '@/assets/images/logo.png';
import sideImg from '@/assets/images/sample-sidebar.jpg';
import moment from 'moment'
import { headerProps } from '@/components/constants';
export default {
  components: {
    HomeTemplate,
  },
  data() {
    return {
      headerProps: {...headerProps, currentRoute: this.$route.name},
      socialProps: {
        facebook_link: "http://facebook.com",
        twitter_link: "http://twitter.com",
        instagram_link: "http://instagram.com"
      },
      mainProps: {
        items: [
          {
            mode: 'dark',
            image: 'https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari1.png',
            label: 'A Place Where Your <br/>Event Shines',
          },
          {
            mode: 'dark',
            image: 'https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari10.png',
            label: 'A Place Where Your <br/>Event Shines',
          },
          {
            mode: 'dark',
            image: 'https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari14.png',
            label: 'A Place Where Your <br/>Event Shines',
          },
          {
            mode: 'dark',
            image: 'https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari19.png',
            label: 'A Place Where Your <br/>Event Shines',
          }
        ]
      },
      bookProps: {
        checkInDate: moment().add(1, 'days').set('hour', 13),
        checkOutDate: moment().add(2, 'days').set('hour', 13),
      }
    }
  },
  async mounted() {
    // const { checkInventory } = await this.actions.inventory.checkInventory({
    //   productId: '641f5e1e24aa9a0008430e30'
    // })
    // console.log(checkInventory, 'checkInventory')
  },
  methods: {
  }
}
</script>

<style scoped></style>
